export enum TAGLookUpEntity {
  parts = 'Parts',
  tools = 'Tools',
  consumables = 'Consumables',
  certificationCodes = 'CertificationCodes',
  osp = 'OSP',
  items = 'Items',
  skills = 'Skills',
  status = 'Status',
  weatherConditions = 'WeatherConditions',
  orderTypes = 'OrderTypes',
  problemCodes = 'ProblemCodes',
  reasonCodes = 'ReasonCodes',
  unitsOfMeasure = 'UnitsOfMeasure',
  equipments = 'Equipments',
  jobs = 'Jobs',
  personnels = 'Personnels',
  workProcedures = 'WorkProcedures',
  failureCodes = 'FailureCodes',
  facilities = 'Facilities',
  requests = 'Requests',
  workOrderTemplates = 'WorkOrderTemplates',
  equipmentGroups = 'EquipmentGroups',
  regions = 'Regions',
  workCodes = 'WorkCodes',
  maintenanceTypes = 'MaintenanceTypes',
  maintenanceCodes = 'MaintenanceCodes',
  equipementPerRequest = 'EquipementPerRequest',
  areas = 'Areas',
  workOrders = 'WorkOrders',
  plannedWorkOrders = 'PlannedWorkOrders',
  workOrderLines = 'WorkOrderLines',
  woJobTasks = 'WoJobTasks',
  jobTasks = 'jobTasks',
  vendors = 'Vendors',
  locations = 'Locations',
  udns = 'Udns',
  personnelGroups = 'personnelGroups',
  glAccounts = 'GLAccounts',
  binCodes = 'BinCodes',
  employees = 'Employees',
  forms = 'Forms',
  lines = 'Details'
}
